import './SettingsApiKeyContent.less'

import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl, useScopedIntlWithTags } from '../../../hooks'
import { deleteApiKey, fetchApiKey, generateApiKey } from '../../../requests'
import {
  DatacIcon,
  DatacInfoRecord,
  DatacInformationMessage,
  DatacLoading,
  DatacMessage,
  DatacTitle
} from '../../common'
import { SettingsLayout } from '../SettingsLayout'
import { SettingsApiKeyDeleteModal } from './SettingsApiKeyDeleteModal'
import { SettingsApiKeyRegenerateModal } from './SettingsApiKeyRegenerateModal'

enum CallType {
  Generate,
  Delete,
  Fetch
}

export const SettingsApiKeyContent: React.VFC = () => {
  const intlApiKey = useScopedIntl('settings.api_key')
  const intlApiKeyWithTags = useScopedIntlWithTags('settings.api_key')
  const intl = useScopedIntl('')
  const [isLoading, setIsLoading] = useState(true)
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)
  const [isRegenerateModalOpened, setIsRegenerateModalOpened] = useState(false)
  const [key, setKey] = useState<string>(null)

  const getCallFunction = (callType: CallType) => {
    if (callType === CallType.Generate) return generateApiKey
    if (callType === CallType.Delete) return deleteApiKey
    return fetchApiKey
  }

  const onCall = (callType: CallType) => {
    setIsLoading(true)
    getCallFunction(callType)({
      onSuccess: key => {
        setKey(key)
        setIsLoading(false)
      },
      onRequestError: code => {
        DatacMessage.genericError(intl, code)
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    onCall(CallType.Fetch)
  }, [])

  const information = () => {
    const docsUrl = `${process.env.GATSBY_BACKEND_API_URL}/docs/public/`
    return (
      <>
        {intlApiKey('information')}
        <a href={docsUrl} target="_blank" rel="noopener noreferrer">
          {docsUrl}
        </a>
      </>
    )
  }

  return (
    <SettingsLayout>
      <div className="api-key-content">
        <div className="api-key-content__header">
          <DatacTitle type="h2">{intlApiKey('title')}</DatacTitle>
          <div className="api-key-content__header__description">{intlApiKey('description')}</div>
        </div>
        <div className="api-key-content__body">
          <DatacLoading isLoading={isLoading}>
            <div className="api-key-content__body__key">
              <DatacInfoRecord icon="key" label={intlApiKey('key')} isHidden isLong value={key} />

              <Button
                onClick={() => setIsRegenerateModalOpened(true)}
                className="api-key-content__body__key__regenerate"
              >
                <DatacIcon name="repeat" type="blue" /> {intlApiKey('regenerate')}
              </Button>
              <Button onClick={() => setIsDeleteModalOpened(true)}>
                <DatacIcon name="trash" type="red" />
              </Button>
            </div>
            <DatacInformationMessage
              className="api-key-content__body__information"
              message={information()}
              type="info"
            />
            <DatacInformationMessage
              className="api-key-content__body__information"
              message={intlApiKeyWithTags('warning')}
              type="warning"
            />
          </DatacLoading>
        </div>
      </div>
      <SettingsApiKeyDeleteModal
        isOpened={isDeleteModalOpened}
        onClose={() => setIsDeleteModalOpened(false)}
        onDelete={() => onCall(CallType.Delete)}
      />
      <SettingsApiKeyRegenerateModal
        isOpened={isRegenerateModalOpened}
        onClose={() => setIsRegenerateModalOpened(false)}
        onRegenerate={() => onCall(CallType.Generate)}
      />
    </SettingsLayout>
  )
}
